<template>
	<p-dialog button="授权" :visible.sync="dialogVisible"
			  width="480px"
			  @confirm="handleConfirm"
			  @cancel="handleCancel"
			  @close="handleClose">
		<span slot="title">认证信息详情</span>
		<div slot="content">
			<div class="info-header">
				<div>
					<label>组织名称：</label>
					<span>{{equipment.name}}</span>
				</div>
				<div>
					<label>组织税号：</label>
					<span>{{equipment.taxNo}}</span>
				</div>
			</div>
			<div class="info-content">
				<div>认证方式：</div>
				<div class="content-items" v-if="equipment.authorizeMode=='ZS'">
					<div>
						<label>税盘模式</label>
						<img v-if="equipment.isReadied" src="@/assets/icon/deduction/success.png" alt="授权icon">
						<img v-else src="@/assets/icon/deduction/faild.png" alt="授权icon">
					</div>
					<div>
						<label>税盘状态：</label>
						<span>{{fmtEnum(equipment.agentStatus, "AgentOnlineStatus")}}</span>
					</div>
					<div>
						<label>助手状态：</label>
						<span>{{fmtEnum(equipment.deviceStatus, "DeviceOnlineStatus")}}</span>
					</div>
					<div>
						<label>税号匹配：</label>
						<span>{{fmtEnum(equipment.matchStatus, "DeviceMatchStatus")}}</span>
					</div>
				</div>
				<div class="content-items" v-if="equipment.authorizeMode=='LS-JG-UKEY'">
					<div>
						<label>UK服务器</label>
						<img v-if="equipment.isReadied" src="@/assets/icon/deduction/success.png" alt="授权icon">
						<img v-else src="@/assets/icon/deduction/faild.png" alt="授权icon">
					</div>
					<div>
						<label>UKey状态:</label>
						<span>{{fmtEnum(equipment.deviceStatus, "DeviceOnlineStatus")}}</span>
					</div>
					<div>
						<label>税号匹配：</label>
						<span>{{fmtEnum(equipment.matchStatus, "DeviceMatchStatus")}}</span>
					</div>
				</div>
				<div class="content-items" v-if="equipment.authorizeMode=='RZS'">
					<div>
						<label>软证方式</label>
						<img v-if="equipment.isReadied" src="@/assets/icon/deduction/success.png" alt="授权icon">
						<img v-else src="@/assets/icon/deduction/faild.png" alt="授权icon">
					</div>
					<div>
						<label>开通状态：</label>
						<span>{{fmtRZS(equipment.deviceStatus)}}</span>
					</div>
					<div>
						<label>税号匹配：</label>
						<span>{{fmtEnum(equipment.matchStatus, "DeviceMatchStatus")}}</span>
					</div>
				</div>
				<div class="content-items" v-if="equipment.authorizeMode=='RPA'">
					<div>
						<label>SD-R-X</label>
						<img v-if="equipment.isReadied" src="@/assets/icon/deduction/success.png" alt="授权icon">
						<img v-else src="@/assets/icon/deduction/faild.png" alt="授权icon">
					</div>
				</div>
				<div class="content-items" v-else>
					<div>
						<label>尚未设置</label>
					</div>
				</div>
			</div>
		</div>
	</p-dialog>
</template>

<script>
    import PDialog from "@/components/pdt/PDialog";
    import {changeMethods} from "@/util/mixins";

    export default {
        name: "IncomeInfoDialog",
        components: {PDialog},
        props: ["visible", "equipment"],
        mixins: {changeMethods},
        data() {
            return {
                dialogVisible: false,
            }
        },
        watch: {
            visible() {
                this.dialogVisible = this.visible;
            }
        },
        methods: {
            // 取消
            handleCancel() {
                this.$emit("cancel");
                this.handleClose();
            },
            // 授权点击
            handleConfirm() {
                if (!this.equipment.isReadied) {
                    this.toast("当前组织设备未就绪", "error");
                    return;
                }
                this.$emit("confirm", true)
                this.handleClose();
            },
            // 弹窗关闭
            handleClose() {
                this.dialogVisible = false
                this.$emit("update:visible", false)
            },
            // 格式化返回值
            fmtEnum(val, type = "") {
                return this.handleValueToLabel(type, val);
            },
            fmtRZS(val){
                return val == "WORKING" ? "已开通":"未开通"
			}
        }
    }
</script>

<style lang="scss" scoped>
	.info-header {
		padding: 24px;
		font-size: 14px;
		border-bottom: 1px solid #E9E9E9;

		div {
			margin-bottom: 12px;
		}

	}

	.info-content {
		display: flex;
		padding: 24px 0;
		font-size: 14px;
		flex-direction: row;

		.content-items {
			flex: auto;
			display: flex;
			padding-left: 6px;
			flex-direction: column;

			div {
				padding-bottom: 16px;
			}

			label {
				width: 80px;
				display: inline-block;
			}
		}

		img {
			width: 20px;
			height: 20px;
			vertical-align: bottom;
		}
	}

	label {
		color: #666666;
	}

	span {
		color: #333333;
	}
</style>
